export const seokeywords = {
  HOME: {
    titlear: "بلو تيم - أول مغسلة ومخفي سيارات متنقلة في ابوظبي",
    title:
      "Blue team Car Wash | Best Car Wash & Doorstep Car Service in Abu Dhabi",
    keywords:
      "car wash Abu Dhabi, mobile car wash, car detailing Abu Dhabi, doorstep car wash, car service Abu Dhabi, Blue team car wash, professional car wash service, eco-friendly car wash,car wash in Musaffah",
    description:
      "Blue Team Car Wash offers the best mobile car wash and doorstep car service in Abu Dhabi. Experience professional car cleaning, detailing, and eco-friendly services right at your doorstep.",
  },
  ABOUT: {
    titlear: "بلو تيم - أول مغسلة ومخفي سيارات متنقلة في ابوظبي",
    title:
      "About Blue Team Car Wash | Professional Car Cleaning Services in Abu Dhabi",
    keywords:
      "about Blue Team Car Wash, car wash company Abu Dhabi, car cleaning experts, car detailing team, eco-friendly car wash services, mobile car wash team,car wash in Musaffah",
    description:
      "Blue Team Car Wash offers the best mobile car wash and doorstep car service in Abu Dhabi. Experience professional car cleaning, detailing, and eco-friendly services right at your doorstep.",
  },
  SERVICES: {
    titlear: "بلو تيم - أول مغسلة ومخفي سيارات متنقلة في ابوظبي",
    title:
      "Car Wash Services in Abu Dhabi | Mobile Car Wash, Detailing, Interior Cleaning",
    keywords:
      "car wash services Abu Dhabi, mobile car wash, car detailing, interior car cleaning, exterior car wash, professional car care, doorstep car services, eco-friendly car wash,car wash in Musaffah",
    description:
      "Blue Team Car Wash offers the best mobile car wash and doorstep car service in Abu Dhabi. Experience professional car cleaning, detailing, and eco-friendly services right at your doorstep.",
  },
  CONTACT: {
    titlear: "بلو تيم - أول مغسلة ومخفي سيارات متنقلة في ابوظبي",
    title:
      "Contact Blue Team Car Wash | Book Your Car Wash Service in Abu Dhabi",
    keywords:
      "contact Blue Team Car Wash, car wash booking Abu Dhabi, customer service, car detailing inquiries, car service appointments, Abu Dhabi car wash contact,car wash in Musaffah",
    description:
      "Blue Team Car Wash offers the best mobile car wash and doorstep car service in Abu Dhabi. Experience professional car cleaning, detailing, and eco-friendly services right at your doorstep.",
  },
  PACKAGES: {
    titlear: "بلو تيم - أول مغسلة ومخفي سيارات متنقلة في ابوظبي",
    title: "Car Wash Packages and Pricing in Abu Dhabi | Blue Team Car Wash",
    keywords:
      "car wash packages Abu Dhabi, Blue Team car wash pricing, car cleaning packages, car detailing deals, affordable car wash, mobile car wash packages, car care pricing,car wash in Musaffah",
    description:
      "Blue Team Car Wash offers the best mobile car wash and doorstep car service in Abu Dhabi. Experience professional car cleaning, detailing, and eco-friendly services right at your doorstep.",
  },
  CARWASH: {
    titlear: "خدمة غسيل سيارات متنقلة في أبوظبي | تنظيف سيارات مريح في موقعك",
    title:
      "Mobile car wash service in Abu Dhabi | Convenient car cleaning at your location",
    keywords:
      "غسيل سيارات متنقل الشامخة, مغسله سيارات متنقله, غسيل سيارات متنقل العين, أفضل غسيل سيارات متنقل, مغسله سيارات متنقل, غسيل سيارات متنقل بني ياس, غسيل سيارات متنقل ابوظبي الشامخة, غسيل متنقل, خدمة غسيل سيارات متنقلة, تنظيف داخلي للسيارات, غسيل سيارات متنقل 24 ساعة, تلميع سيارات متنقل, غسيل سيارات قريب مني, غسيل سيارات عند المنزل, غسيل سيارات بالمنزل, غسيل سيارات في الموقع",
    description:
      "خدمة غسيل سيارات متنقلة في أبوظبي والعين. نقدم غسيل، تلميع، وتنظيف داخلي عالي الجودة، مع خدمة 24/7 في موقعك. احجز الآن للحصول على سيارة نظيفة بسهولة وراحة",
  },
};
